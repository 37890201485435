'use strict';

angular.module('cpformplastApp.items')
    .factory('WeekSchedule', function(SubjobSchedule) {

    class WeekSchedule {
        constructor({title, startDate, endDate, schedules}) {
          this.title = title;
          this.startDate = new Date(startDate);
          this.endDate = new Date(endDate);
          this.schedules =  schedules.map(json => new SubjobSchedule(json));
        }
    }

    return WeekSchedule;
});
